<template>
  <div>
    <header class="header">
      <a target="_blank" :href="url">
        <img height="40" src="/img/logo.6880cf15.png" class="logo "
      /></a>
      <a target="_blank" href="https://greenn.crisp.help/pt-br/"
        >Preciso de ajuda</a
      >
    </header>
    <section v-if="!loading" class="mx-5 px-5 py-3">
      <b-row class="container-batch my-4 ">
        <b-col :cols="dynamicCol" class="p-4">
          <div
            class="mb-2 d-flex justify-content-between align-item-center px-4 pt-4"
          >
            <div>
              <h1 class="title mb-2">{{ dataTicket.clientName }}</h1>
              <p class="subtitle mb-1">{{ dataTicket.batcheName }}</p>
              <p class="ticket mb-1">{{ dataTicket.offerName }}</p>
            </div>
            <div class="status mt-4">
              Disponível
            </div>
          </div>
          <div class="px-4 client-data">
            <h2 class="my-4">CPF: {{ dataTicket.documentNumber }}</h2>
            <h2 class="my-4">E-mail: {{ dataTicket.email }}</h2>
            <h2 class="my-4">Telefone: {{ dataTicket.phone }}</h2>
          </div>
        </b-col>
        <b-col :cols="dynamicCol" class="p-4">
          <div class="mb-1 px-4 pt-4">
            <h1 class="title mb-3">{{ dataTicket.eventName }}</h1>
            <div class="d-flex justify-content-between align-item-center">
              <div class="flex justify-between">
                <ul class="mr-1">
                  <li class="d-flex align-items-center my-4" v-if="dataTicket.date">
                    <img
                      height="25"
                      src="@/assets/img/icons-batch/calendar.svg"
                      class="logo mr-3"
                    />
                    <span class="client-data">
                      {{ dataTicket.date }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center my-4" v-if="dataTicket.time">
                    <img
                      height="25"
                      src="@/assets/img/icons-batch/clock.svg"
                      class="logo mr-3"
                      style="margin-left: -1px;"
                    />
                    <span class="client-data">
                      {{ dataTicket.time }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center my-4" v-if="dataTicket.location">
                    <img
                      height="25"
                      src="@/assets/img/icons-batch/location.svg"
                      class="logo mr-3"
                      style="margin-left: 2px;"
                    />
                    <span class="client-data">
                      {{ dataTicket.location }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="calendar-card">
                <div class="calendar-header">
                  <span>{{ eventMonth }}</span>
                </div>
                <div class="calendar-content">
                  <span>{{ eventDay }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-item-center container-share"
          >
            <h1 class="share px-4">Compartilhe</h1>
            <div
              class="d-flex justify-content-between align-item-center mr-4"
              style="gap:20px"
            >
              <img
                src="@/assets/img/icons-batch/whats.svg"
                alt="icon-whats"
                class="icon"
                @click="redirect('whats')"
              />
              <img
                src="@/assets/img/icons-batch/face.svg"
                alt="icon-face"
                class="icon"
                @click="redirect('face')"
              />
              <img
                src="@/assets/img/icons-batch/twitter.svg"
                alt="icon-twitter"
                class="icon"
                @click="redirect('twitter')"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-5">
        <b-col :cols="dynamicCol" class="">
          <h1 class="titles mb-1">Detalhes do evento</h1>
          <p class="detail-event mb-5 mr-4">
            {{ dataTicket.description }}
          </p>
          <h1 class="titles mb-1" v-if="eventLocation">Local do evento</h1>
          <p class="detail-event mb-5" v-if="eventLocation">
            {{ eventLocation }}
          </p>
          <h1 class="titles mb-1">Organizadores</h1>
          <p class="detail-event mb-1">{{ dataTicket.organizers }}</p>
        </b-col>
        <b-col :cols="dynamicCol" class="pl-5">
          <img
            v-if="dataTicket && dataTicket.images"
            :src="eventBanner"
            alt="banner"
            class="banner"
          />
          <img
            v-else
            src="@/assets/img/icons-batch/img_null.svg"
            alt="banner"
            class="banner"
          />
        </b-col>
      </b-row>
    </section>
    <!-- Loading -->
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import TicketService from "@/services/resources/TicketService";
const serviceTicket = TicketService.build();

export default {
  name: "ViewBatch",
  data() {
    return {
      loading: false,
      dataTicket: {},
      client: {
        width: 0,
      },
    };
  },
  computed: {
    url() {
      return process.env.VUE_ADM_PAGE;
    },
    eventBanner() {
      return this.dataTicket?.images[0]?.path;
    },
    eventDay() {
      return this.dataTicket?.calendarDay?.day;
    },
    eventMonth() {
      return this.dataTicket?.calendarDay?.month.toUpperCase();
    },
    eventLocation() {
      if(!this.dataTicket?.eventAddress?.location){
        return null
      }
      return `${this.dataTicket?.eventAddress?.location} |
        ${this.dataTicket?.eventAddress?.street}, 
        ${this.dataTicket?.eventAddress?.neighborhood}, 
        ${this.dataTicket?.eventAddress?.city} - 
        ${this.dataTicket?.eventAddress?.state}, 
        ${this.dataTicket?.eventAddress?.number}, 
        ${this.dataTicket?.eventAddress?.country}
        `;
    },
    dynamicCol() {
      if (this.isMobile) {
        return 12;
      }
      return 6;
    },
    isMobile() {
      if (this.client.width <= 768) {
        var mainMobile = document.querySelector(".main");
        if(mainMobile){
          mainMobile.classList.remove("mobile");
          mainMobile.style.marginTop = "0px !important";
          mainMobile.style.padding = "0 15px";
          mainMobile.style.maxWidth = "100%";
        }
      }
      return this.client.width <= 768;
    },
  },
  methods: {
    async fetchTicket() {
      this.loading = true;
      await serviceTicket
        .read(`/show/${this.$route?.params?.hash}`)
        .then((res) => {
          this.dataTicket = res;
          this.loading = false;
        })
        .catch(() => {
          this.$bvToast.toast("Não foi possivel buscar seu ingresso", {
            title: "Greenn",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          setTimeout(() => {
            this.$router.push({ name: "pageNotFound" });
          }, 5000);
        });
    },
    redirect(social) {
      const url = window.location.href;
      const links = {
        whats: `https://api.whatsapp.com/send?text=${url}`,
        face: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        twitter: `https://twitter.com/intent/tweet?text=${url}`,
      };
      window.open(links[social], "_blank");
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  async mounted() {
    await this.fetchTicket();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    if (this.client.width <= 768) {
      var mainMobile = document.querySelector(".main");
      if(mainMobile){
        mainMobile.classList.add("mobile");
        mainMobile.style.marginTop = "";
        mainMobile.style.padding = "";
        mainMobile.style.maxWidth = "";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.calendar-content {
  display: flex;
  height: 63px;
  width: 100%;
  align-items: center;
  justify-content: center;
  span {
    font-weight: 700;
    font-size: 32px;
  }
}
.calendar-header {
  text-align: center;
  background-color: #009488;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
  }
}
.calendar-card {
  height: 94px;
  width: 85px;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: #e5e5e5;
  background: #fff;
}
.banner {
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.icon {
  cursor: pointer;
}
.share {
  font-size: 16px;
  font-weight: 500;
  color: #81858e !important;
}
.detail-event {
  font-size: 15px;
}
.titles {
  font-size: 16px;
  font-weight: 700;
  color: #009488;
}
.status {
  color: #009488;
  background: #0094881a;
  border-radius: 10px;
  display: grid;
  align-items: center;
  padding: 10px;
  height: 40px;
}
.container-batch {
  background: #ededf0;
  border-radius: 10px;
  h1,
  h2,
  p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 15px;
    font-weight: 400;
    color: #7a7786;
  }
  .ticket {
    font-size: 15px;
    color: #009488;
  }
  .client {
    font-size: 16px;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededf0 !important;
  position: relative;
  padding: 10px 40px;
  img {
    width: 200px;
    height: auto;
  }
  a {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    text-decoration: none;
  }
  &::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 1px;
    background: #ededf0;
    bottom: -1px;
    left: -70px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 1px;
    background: #ededf0;
    bottom: -1px;
    right: -70px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 10px 0px;
    img {
      width: 60%;
    }
  }
  .title.mb-3 {
    margin-top: 1rem !important;
  }
  .my-4 {
    margin-bottom: 0.8rem !important;
    margin-top: 0.8rem !important;
  }
  .p-4,
  .px-4,
  .px-5 {
    padding: 0 !important;
  }
  .banner,
  .calendar-card {
    display: none;
  }
  .container-share {
    background: #ededf0;
    background: #ededf0;
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
  }
  .container-batch {
    background: #fff;
    .title {
      word-break: break-all;
    }
  }
}
.client-data {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141414;
}
</style>
